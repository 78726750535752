import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { getCognitoUser, saveLocalToken } from 'app/pages/Auth/slice/authSlice';
import { Epath } from 'app/routes/routesConfig';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getLocalItem, removeLocalItem } from 'utils/localStorage';
import authApi from './api/authApi';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  // Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
};

// Function to clear session data and redirect to login screen
const clearSessionAndRedirect = () => {
  removeLocalItem('token');
  removeLocalItem('refreshToken');
  removeLocalItem('cognitoEmail');
  window.location.href = Epath.pathLogin; // Adjust this path to your actual login path
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getLocalItem('token');
  if (token != null && config.headers) {
    config.headers.Authorization = `${token}`;
  }

  return config;
};

class Http {
  private instance: AxiosInstance | null = null;
  private retryCount: number = 0;
  private readonly maxRetryCount: number = 3;
  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      // baseURL: 'http://13.113.22.172/api/v1/',
      baseURL: process.env.REACT_APP_BASE_API_URL,
      // baseURL: 'https://xcare-api.inteam.jp/api/v1/',
      headers,
    });

    http.interceptors.request.use(injectToken, (error) => Promise.reject(error));

    http.interceptors.response.use(
      (response) => response,
      (error) => this.handleError(error),
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.patch<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private async handleError(error) {
    const { response, config } = error;

    if (response && response.status === 400) {
      console.warn('Bad Request: Ignoring 400 error', response);
      // Optionally, you can return a custom error object or simply resolve with a default response
      return Promise.resolve({ data: null }); // Returning an empty response or a custom response object
    }

    if (config.url !== '/auth' && config.url !== Epath.pathSignUp && response) {
      if (
        (response.status === StatusCode.Forbidden ||
          response.status === StatusCode.InternalServerError) &&
        // ||
        // response.status === StatusCode.Unauthorized
        !config._retry &&
        this.retryCount < this.maxRetryCount
      ) {
        config._retry = true;
        this.retryCount++;
        // handle refresh token
        try {
          const cognitoEmail = getLocalItem('cognitoEmail');
          const refreshToken = getLocalItem('refreshToken');
          const cognitoUser = getCognitoUser(cognitoEmail ?? '');
          if (cognitoUser && refreshToken) {
            const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
            const refreshPromise = () => {
              return new Promise((resolve, reject) => {
                cognitoUser.refreshSession(cognitoRefreshToken, (err, session) => {
                  if (err) {
                    reject(err);
                  } else {
                    authApi.signIn();
                    resolve(session);
                  }
                });
              });
            };
            const session: any = await refreshPromise();

            saveLocalToken(session.idToken.jwtToken, session.refreshToken.token);
            // Make the retry request with the updated config
            return this.http({
              ...config,
              headers: {
                ...config.headers,
                Authorization: session.idToken.jwtToken,
              },
            });
          }
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (
        response.status === StatusCode.Unauthorized ||
        response.status === StatusCode.Forbidden
      ) {
        // clearSessionAndRedirect();
      }
    }

    return Promise.reject(error);
  }
}

export const axiosClient = new Http();
